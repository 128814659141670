<template lang="pug">
  v-container.custom-container()
    v-layout(column align-center)
      v-layout(column xs12='' align-center).pt-2.ma-2.mt-3.text-center
        .title(style="color: rgb(76,76,76); font-size: 2.2rem !important; letter-spacing: -1.5px !important;") {{title}}
        v-divider.primary.mt-5( style="width: 100px;")
      v-layout(column style="font-size: 15px;" ).text-xs-justify.pa-3
        v-expansion-panels
          h2.mt-10.mb-5 Contributors
          v-expansion-panel(v-for="(item,i) in questionsContributors" :key="i")
            v-expansion-panel-header {{item.question}}
            v-expansion-panel-content {{item.answer}}
          h2.mt-10.mb-5 Customer
          v-expansion-panel(v-for="(item,i) in questionsBuyers" :key="i")
            v-expansion-panel-header {{item.question}}
            v-expansion-panel-content {{item.answer}}
</template>

<script>
  import mapImage from '@/assets/images/icon-faq.png'
  export default {
    data () {
      return {
        title: 'Frequently Asked Question’s',
        mapImage: mapImage,
        questionsContributors: [
          {
            question: 'How do I post a video on Scene Finder’s website or mobile app?',
            answer: 'You access the footage of the accident, incident or event that you want to upload on Scene Finder from the device that you recorded it on. Then you share the data by logging into your Scene Finder account, click on the share button and upload the footage.',
          },
          {
            question: 'From what device am I able to capture and share the footage?',
            answer: 'You can capture and share the footage from any of your devices such as cell phone, home security camera, dash cam and so forth.',
          },
          {
            question: 'If I am unable to share the footage from the device that I initially recorded it on, am I still able to share it?',
            answer: ' Yes, you can simply make a copy of the footage by using any other device such as your cell phone and then upload the content, we just ask that you review and confirm it’s visible before you upload.',
          },
          {
            question: 'Will I still have the rights to my footage?',
            answer: ' Yes, the footage is still yours however, you will need to sign a release given us authorization in order for Scene finder to publish the footage on our site.',
          },
          {
            question: 'Can I withdraw my signed release once I upload the footage if I change my mind?',
            answer: 'No, once you sign the release and upload the content we cannot remove the footage because we may have already shared the footage with others or in the process of doing so.',
          },
          {
            question: 'How do I get paid for the footage that I uploaded?',
            answer: 'Once the customer who is looking for the exact footage that you uploaded search our database, locate the footage and pays for it. You are then notified within 24 hours of the payment being processed by email of the purchase and advised to log into your account to request your payment method.',
          },
          {
            question: 'How can we be sure that Scene Finder will pay for the footage once the customer purchases the content?',
            answer: ' Keep in mind that the main premise of Scene Finder is based on Integrity. If the footage is paid for and we do not pay for the footage we would risk our contributors finding out and since our business is based on our contributors uploading the footage that would not be good business. In addition, we have a second process in place to confirm that the rewards are paid out. We also have a notification email in place that if the payment is not issued and no choice was made on payment processing, to submit an additional notification email. We also have a check and balance verification process in place to confirm that our contributors are paid. If you received notification of your payment but did not receive your reward that you requested. Please send us an email and we will be happy to resolve the matter.',
          },
          {
            question: 'If I want to upload a footage but I am just doing it as a Good Samaritan and do not want to be rewarded what are my options?',
            answer: 'A big part of Scene Finder is charity, we believe that charity starts at home so you will have the option to issue your reward to the charity of your choice or you can select a charity from our list. Once your reward is issued to your selected charity organization on your behalf, you will receive an email notification which you can also use for tax purposes if you chose.',
          },
          {
            question: 'When does my charity organization that I select to get my reward receive the funds on my behalf?',
            answer: 'Instead of sending multiple checks every time someone selects their charity. Charity organization payments will be processed at the end of every month and the check should be received within 5-10 business days after its processed.',
          },
          {
            question: 'Will the customer know who provided the footage?',
            answer: 'No, your information will be kept completely anonymous.',
          },
          {
            question: 'What if I only witness the accident, incident or event but I do not have any footage can I still provide my information and receive a reward.',
            answer: 'No, Scene Finder is based solely on evidence base content.',
          },
          {
            question: 'Is there a minimum amount to claim my reward?',
            answer: 'Yes, there is a minimum of $24.99 that will need to be in your account in order to claim your reward.',
          },
          {
            question: 'Will I be informed who purchased my video content?',
            answer: 'No, both the contributor and the customer’s information are anonymous.',
          },
          {
            question: 'Do I have to pay taxes on my rewards?',
            answer: 'The federal government does not require you to pay taxes on payment you received that is under $600. However, please refer to your accountant on each state requirement. ',
          },
          {
            question: 'How much will I be paid for my footage?',
            answer: ' You will receive 25% of the refund for our general single purchase even if the charge is discounted for our subscribers. For pictures you will receive $24.99 and for video its $37.49',
          },
          {
            question: 'Why is the reward for the video content more than the pictures?',
            answer: 'Video content are easier to prove liability than pictures and videos add more value.',
          },
          {
            question: 'Who do I contact if I have an issue with my account or upload?',
            answer: 'Send an email to https://scenefinder.com/customerservice and provide a description of your concern.',
          },
          {
            question: 'Why wasn’t may content uploaded on the site?',
            answer: 'If your content was received and it was not uploaded, we may have an issue with the footage however, you will receive notification if we receive it and we are unable to upload your footage.',
          },
          {
            question: 'What are the different payment options to receive my payment?',
            answer: 'Your reward can be processed as charity credit, Scene Finder Visa gift card, PayPal, direct deposit or check.',
          },
          {
            question: 'If my footage is blurry can I still upload it and get paid.',
            answer: 'No, the footage needs to be clear and visible in order for us to upload it on the site. If it is not clear you will receive an email notifying you that we are unable to post your content. The reason is based on integrity and excellence we have to add value to the party that’s paying for the footage.',
          },
          {
            question: 'When shall I upload the content that I have?',
            answer: 'You should try and upload the content the same day. Liability decisions are made very quickly, usually within 1-2 days after the claim is filed. Therefore, the quicker you upload the content the great your chance of someone looking and finding your footage and you being paid.',
          },
          {
            question: 'Who do I contact if I have an issue with my account or upload?',
            answer: 'Send an email to https://scenefinder.com/customerservice and provide a description of your concern.',
          },
        ],
        questionsBuyers: [
          {
            question: 'How do I locate the footage that I am looking for?',
            answer: 'You would click search and enter some basis parameters such as date the accident, incident or event occurred, street location, city and state, approximate time or as much information that you have in order to narrow your search. You also want to do multiple search by adding and remove some fields to ensure you’re getting the correct response.',
          },
          {
            question: 'How can I be sure that I am purchasing the correct footage?',
            answer: 'We offer a thumbnail of the image or a 3 second video footage that the customer can review to confirm its the correct evidence prior to purchase.',
          },
          {
            question: 'What is the refund process?',
            answer: 'We do not offer refunds, since we offer the thumbnail prior to purchase, we ask that you review the details prior to purchasing since its non-refundable.',
          },
          {
            question: 'Will the contributor be informed about who purchased the footage?',
            answer: 'No, neither the contributor nor the customer’s information is shared, both party’s details are anonymous. ',
          },
          {
            question: 'If the footage that was purchased is not visible what are my options?',
            answer: 'Since the video is reviewed prior to being uploaded on the site, you can send us an email notifying us of the error, we will review and resend the footage. ',
          },
          {
            question: 'What are your payment options?',
            answer: 'You can purchase the footage as a single one time purchase or you can setup a monthly subscription at a discounted rate.',
          },
          {
            question: 'What is the cost of a single purchase?',
            answer: 'For a 1x purchase of a video footage the cost is $149.95 and for image its $99.95',
          },
          {
            question: 'Why is the charge for the video content more than the pictures?',
            answer: 'Video content are easier to prove liability than pictures.',
          },
          {
            question: 'What is the cost of the monthly subscription purchase?',
            answer: 'For subscription fee breakdown see subscription and discount offers.',
          },
          {
            question: ' After I purchase the video how do I receive the content?',
            answer: 'You have the option to view and/or upload the content. The content will also stay in your account for up to 1 year, after that it maybe archived. So you can log back into your account if you need to share it after your purchase is completed.',
          },
          {
            question: 'Can I get the details of the contributor to obtain a witness statement?',
            answer: 'No, all party’s information is anonymous.',
          },
          {
            question: 'Why charge a fee for the evidence?',
            answer: 'Contributors are more likely to upload the content if they anticipate being paid, so part of charging the fee is to encourage contributors to quickly upload the footage so they can be refunded.',
          },
          {
            question: 'If I upload the video footage but did not receive the content, what do I do?',
            answer: 'You can access your account that will have the content you purchased and you can them resent the content to yourself. If you are still having an issue, please email us and we will be happy to assist you.',
          },
          {
            question: 'What method of payment can I use to purchase the content?',
            answer: 'You can use a variety of payment method to purchase the content such as visa, master card, American express, discover and debit card.',
          },
          {
            question: 'Who do I contact if I have an issue with my account or purchase?',
            answer: 'Send an email to https://scenefinder.com/customerservice and provide a description of your concern.',
          },
          {
            question: 'How does the subscription plan work and can I cancel my subscription?',
            answer: 'If you select any of our subscription service, the plan renews every month. You can cancel your subscription at any time during your plan term.',
          },

        ],
        questions: [
          {
            question: ' ¿Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod? ',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
          {
            question: ' ¿Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod? ',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
          {
            question: ' ¿Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod? ',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          },
        ],
        item: {
          pictures: {
            imageInput1: '',
          },
        },
      }
    },
  }
</script>

<style lang='scss' scoped>
    $primary-color: var(--secondary-color);
    $text-color: #858585;
    $main-font : "Calibri";
    .search {
        height: 40px;
        overflow: hidden;
        border: 1px solid black;
        border-radius: 25px;
    }

    .glide__slides{
        width: max-content !important;
    }
    .glide__slide{
        width: 275px !important;
        margin: 0 18px !important;
    }
    .border-right{
        border-right: 2px solid rgba(#777,0.5);
    }
    .border-bottom{
        border-bottom: 2px solid rgba(#777,0.5);
    }

    .siema{
        padding: 0 30px;
    }
    .filter-box{
        display: flex;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 1;

    }

    .blog-list{
        width: 100vw;
        padding: 0 10px;
    }

</style>
